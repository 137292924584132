<template>
  <div class="pageMain">
    <search-head :model="searchForm" @search="search" @reset="reset">
      <el-form :inline="true" :model="searchForm">
        <el-form-item label="所属公司">
          <el-input
            v-model="searchForm.orgaPName"
            size="small"
            placeholder="所属公司"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系人">
          <el-input
            v-model="searchForm.projHead"
            size="small"
            placeholder="联系人"
          ></el-input>
        </el-form-item>
        <el-form-item label="项目状态">
          <el-select
            v-model="searchForm.projState"
            size="small"
            placeholder="项目状态"
          >
            <el-option
              v-for="(item, index) in projState"
              :key="index"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="项目名称">
          <el-input
            v-model="searchForm.projName"
            size="small"
            placeholder="项目名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="开始日期">
          <el-date-picker
            v-model="searchForm.startTime"
            type="datetime"
            placeholder="开始日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束日期">
          <el-date-picker
            v-model="searchForm.endTime"
            type="datetime"
            placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
    </search-head>
    <custom-tables
      :data="tableData"
      :headerCellStyle="headerCellStyle"
      @selectionChange="handleSelectionChange"
      @handleCurrentChange="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
    
      :total="total"
      title="项目信息"
    >
      <template slot="btns">
        <el-button
          type="success"
          size="small"
          @click="addFrom"
          icon="el-icon-plus"
          class="addBtn"
          >新增</el-button
        >
      </template>
      <el-table-column type="selection" align="center" width="55">
      </el-table-column>
      <el-table-column prop="projName" label="项目名称" align="center"  show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="projHead" align="center" label="负责人">
      </el-table-column>
      <el-table-column prop="projTel" align="center" label="联系电话">
      </el-table-column>
      <el-table-column prop="projState" align="center" label="项目状态">
        <template slot-scope="scope">
          <div v-if="scope.row.projState == 0">正常</div>
          <div v-if="scope.row.projState == 1">停工</div>
          <div v-if="scope.row.projState == 2">完结</div>
        </template>
      </el-table-column>
      <el-table-column prop="orgaPName" align="center" label="所属公司"  show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="deviceCount" align="center" label="设备总数">
      </el-table-column>
      <el-table-column prop="deviceOnLineCount" align="center" label="设备在线总数">
      </el-table-column>
      <el-table-column prop="startTime" align="center" label="开工日期"  show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="endTime" align="center" label="结束日期"  show-overflow-tooltip>
      </el-table-column>

      <el-table-column label="操作" align="center" width="100">
            <template slot-scope="scope">
              <span
                @click="detailItem(scope.row)"
                class="detail"
              >
              <i class="el-icon-edit"></i>
            </span>
            <span
                @click="deleteItem(scope.row)"
                class="detail delete"
              >
              <i class="el-icon-delete"></i>
            </span>
            </template>
          </el-table-column>
    </custom-tables>

    <projectEdit :visible.sync="showDialog" @close="colseDialog" :title="title" @done="doneDialog" :item="item">
    </projectEdit>
  </div>
</template>

<script>
import SearchHead from "../../../components/common/SearchHead";
import CustomTables from "../../../components/common/CustomTables";
import projectEdit from "../../../components/basic/projectEdit.vue";
import basic from '../../../api/modules/basic'
import { projectState } from "../../../config/dataStatus";

export default {
  name: "index",
  components: { projectEdit, CustomTables, SearchHead },
  data() {
    return {
      tableData: [],
      searchForm: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      showDialog: false,
      headerCellStyle: {
        backgroundColor: "#f3f2f7",
        color: "#373628",
      },
      options: [],
      title:'',
      item:{},
      projState:projectState
    };
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
        basic.GetProjectListByPage(this.searchForm).then((res) => {
        this.total = res.data.total;
        this.tableData = res.data.records;
      });
    },
    search() {
      console.log("searchForm", this.searchForm);
      this.searchForm.pageNum = 1;
      this.searchForm.pageSize = 10;
      this.getList();
    },
    reset() {
      this.searchForm = {
        pageNum: 1,
        pageSize: 10,
      };
      this.getList();
    },
    doneDialog(){
        this.showDialog = false
        this.getList()
      },
    addFrom() {
      this.item ={}
      this.showDialog = true;
      this.title = "新增项目";
    },
    colseDialog() {
      this.showDialog = false;
    },
    submit() {
      this.showDialog = false;
    },
    detailItem(item) {
      //详情
      this.showDialog = true;
      this.title = "编辑项目";
      this.item = item
    },
    deleteItem(item) {
      let param = {
        projId: item.projId
      }
      this.$confirm(`此操作将永久删除该项目, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })

          .then(() => {
            basic.delProject(param).then((res) => {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.getList();
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
    handleSelectionChange(val) {
      console.log(val);
    },
    handleCurrentChange(pageIndex) {
      this.searchForm.currPage = pageIndex
      this.getList()
    },
    handleSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.currPage = 1
      this.getList()
    },
  },
};
</script>

<style scoped lang="less">
.detail {
  color: #008f4d;
}
.delete {
  margin-left: 10px;
}
.addBtn {
  background-color: #008f4d;
}
</style>
