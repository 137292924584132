<template>
  <custom-dialog
    :visible.sync="visible"
    :title="title"
    @close="colseDialog"
    @submit="submit"
    width="60%"
  >
    <div class="editTitle">基本信息</div>
    <div class="edit-main">
      <el-row :gutter="20">
        <el-form
          :model="editForm"
          label-width="140px"
          :rules="rules"
          ref="editCopanyForm"
        >
          <div class="inputFlex">
            <div class="box">
              <el-col :span="24">
                <el-form-item label="项目名称：" prop="projName">
                  <el-input
                    size="small"
                    v-model="editForm.projName"
                    placeholder="请输入项目名称"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="所属机构" prop="orgaIdArr">
                  <el-cascader
                    size="small"
                    class="block-select"
                    v-model="editForm.orgaIdArr"
                    :options="copanyListInfo"
                    @change="cascaderChange"
                  ></el-cascader>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="省:" prop="areaPid">
                  <el-select
                    class="block-select"
                    size="small"
                    filterable
                    v-model="editForm.areaPid"
                    placeholder="请选择所在省"
                    @change="changeAreaParent"
                  >
                    <el-option
                      v-for="item in areaParentList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="市:" prop="areaId">
                  <el-select
                    class="block-select"
                    size="small"
                    filterable
                    v-model="editForm.areaId"
                    placeholder="请选择所在市"
                    @change="changeArea"
                  >
                    <el-option
                      v-for="item in areaList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select> </el-form-item
              ></el-col>
              <el-col :span="24">
                <el-form-item label="地址:" prop="projAddress">
                  <el-input
                    size="small"
                    v-model="editForm.projAddress"
                    @input="updateMapCenter"
                    placeholder="请输入地址"
                  ></el-input> </el-form-item
              ></el-col>
              <el-col :span="24">
                <el-form-item label="项目状态：" prop="projState">
                  <el-select
                    class="block-select"
                    v-model="editForm.projState"
                    size="small"
                    placeholder="请选择项目状态"
                  >
                    <el-option
                      v-for="item in projectState"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select> </el-form-item
              ></el-col>
              <el-col :span="24">
                <el-form-item label="项目经理：" prop="projHead">
                  <el-input
                    size="small"
                    v-model="editForm.projHead"
                    placeholder="请输入项目经理"
                  ></el-input> </el-form-item
              ></el-col>
              <el-col :span="24">
                <el-form-item label="联系电话：" prop="projTel">
                  <el-input
                    size="small"
                    v-model="editForm.projTel"
                    placeholder="请输入联系电话"
                  ></el-input> </el-form-item
              ></el-col>
              <el-col :span="24"
                ><el-form-item label="开工日期：" prop="startTime">
                  <el-date-picker
                    class="date"
                    v-model="editForm.startTime"
                    format="yyyy-MM-dd HH:mm:ss"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    type="datetime"
                    placeholder="选择开工日期"
                  >
                  </el-date-picker> </el-form-item
              ></el-col>
            </div>

            <baidu-map
              :center="center"
              :zoom="zoom"
              class="mapbox"
              :scroll-wheel-zoom="true"
              @ready="handler"
              @click="getPoint"
            >
              <bm-marker
                :position="{
                  lng: editForm.projLong,
                  lat: editForm.projLot,
                }"
              ></bm-marker>
            </baidu-map>
          </div>
          <el-col :span="12">
            <el-form-item label="竣工日期：" prop="endTime">
              <el-date-picker
                class="date"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                v-model="editForm.endTime"
                type="datetime"
                placeholder="选择竣工日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="建设单位：" prop="projOwnerUnit">
              <el-input
                size="small"
                v-model="editForm.projOwnerUnit"
                placeholder="请输入建设单位"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="12">
            <el-form-item label="联系人员(建设)：" prop="ownerUnitLinker">
              <el-input
                size="small"
                v-model="editForm.ownerUnitLinker"
                placeholder="请输入联系人员(建设)"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="12">
            <el-form-item label="联系电话(建设)：" prop="ownerUnitTel">
              <el-input
                size="small"
                v-model="editForm.ownerUnitTel"
                placeholder="请输入联系电话(建设)"
              ></el-input>
            </el-form-item>
            
           </el-col>
           <el-col :span="12"> <el-form-item label="监理单位：" prop="projSuperUnit">
              <el-input
                size="small"
                v-model="editForm.projSuperUnit"
                placeholder="请输入监理单位"
              ></el-input> </el-form-item
          ></el-col>
          
          <el-col :span="12"><el-form-item label="联系人员(监理)：" prop="superUnitLinker">
            <el-input
              size="small"
              v-model="editForm.superUnitLinker"
              placeholder="请输入联系人员(监理)"
            ></el-input>
          </el-form-item></el-col>
          <el-col :span="12"> <el-form-item label="联系电话(监理)：" prop="superUnitTel">
            <el-input
              size="small"
              v-model="editForm.superUnitTel"
              placeholder="请输入联系电话(监理)"
            ></el-input>
          </el-form-item></el-col>
          <el-col :span="12"><el-form-item label="施工单位：" prop="projConstUnit">
            <el-input
              size="small"
              v-model="editForm.projConstUnit"
              placeholder="请输入施工单位"
            ></el-input>
          </el-form-item></el-col>
          <el-col :span="12"><el-form-item label="联系人员(施工)：" prop="constUnitLinker">
            <el-input
              size="small"
              v-model="editForm.constUnitLinker"
              placeholder="请输入联系人员(施工)"
            ></el-input>
          </el-form-item></el-col>
          <el-col :span="12"> <el-form-item label="联系电话(施工)：" prop="constUnitTel">
            <el-input
              size="small"
              v-model="editForm.constUnitTel"
              placeholder="请输入联系电话(施工)"
            ></el-input>
          </el-form-item></el-col>
          
         
          
          
         
        </el-form>
      </el-row>
    </div>
  </custom-dialog>
</template>

<script>
import basic from "../../api/modules/basic";
import CustomDialog from "../../components/common/CustomDialog.vue";
import organizationTree from "../../api/modules/organizationTree";
import { organizationCascader, phoneReg } from "../../utils/tool";
import { projectState } from "../../config/dataStatus";

//   import config from "../../../api/config";
export default {
  name: "projectEdit",
  props: ["visible", "title", "item"],
  components: { CustomDialog },
  watch: {
    item(newVal, oldVal) {
      if (newVal.projId) {
        // 编辑
        this.editForm = newVal;
        this.orgaId = newVal.orgaId;
        let item = newVal;
        console.log("item", item);

        if (item.orgaPathIds && item.orgaPathIds.match("-")) {
          const splitArray = item.orgaPathIds.split('-');
        const numericArray = splitArray[splitArray.length - 1] === '' ? splitArray.slice(0, -2) : splitArray;
        let orgaIdArr = numericArray.map(item => Number(item));
        this.editForm.orgaIdArr = orgaIdArr
      
        } else if (item.orgaPathIds) {
          this.editForm.orgaIdArr = [Number(item.orgaPathIds)];
          // this.editForm.orgaIdArr=newVal.orgaIdArr
        }
       
        if (newVal.areaPid) {
          this.getAreaInfo(newVal.areaPid);
        }
      } else {
        this.editForm = {
          projName: "",
          orgaPid: "",
          areaPid: "",
          areaId: "",
          projAddress: "",
          projState: 0,
          projHead: "",
          projTel: "",
          startTime: "",
          endTime: "",
          orgaIdArr: [],
        };
      }
      this.resetFields();
    },
  },
  data() {
    let checkMobile = (rule, value, callback) => {
      if (!phoneReg(value)) {
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    };
    return {
      center: { lng: 116.404, lat: 39.915 },
      zoom: 15,
      editForm: {
        projName: "",
        orgaPid: "",
        areaPid: "",
        areaId: "",
        projAddress: "",
        projState: "",
        projHead: "",
        projTel: "",
        startTime: "",
        endTime: "",
        orgaIdArr: [],
      },
      rules: {
        projName: [
          { required: true, message: "请输入项目名称", trigger: "blur" },
        ],
        projHead: [
          { required: true, message: "请输入项目经理", trigger: "blur" },
        ],
        projTel: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
          { validator: checkMobile, trigger: "blur" },
        ],
        areaPid: [{ required: true, message: "请选择省", trigger: "change" }],
        areaId: [{ required: true, message: "请选择市", trigger: "change" }],
        orgaIdArr: [
          { required: true, message: "请选择所属机构", trigger: "change" },
        ],
        projState: [
          { required: true, message: "请选择项目状态", trigger: "change" },
        ],
        projAddress: [
          { required: true, message: "请输入地址", trigger: "change" },
        ],
        startTime: [
          { required: true, message: "请选择开工时间", trigger: "change" },
        ],
        endTime: [
          { required: true, message: "请选择竣工日期", trigger: "change" },
        ],
        projOwnerUnit: [
          { required: true, message: "请输入建设单位", trigger: "blur" },
        ],
        ownerUnitLinker: [
          { required: true, message: "请输入联系人员(建设)", trigger: "blur" },
        ],
        ownerUnitTel: [
          { required: true, message: "请输入联系电话(建设)", trigger: "blur" },
          { validator: checkMobile, trigger: "blur" },
        ],
        projSuperUnit: [
          { required: true, message: "请输入监理单位：", trigger: "blur" },
        ],
        superUnitLinker: [
          {
            required: true,
            message: "请输入联系人员(监理)：",
            trigger: "blur",
          },
        ],
        superUnitTel: [
          {
            required: true,
            message: "请输入联系电话(监理)：",
            trigger: "blur",
          },
          { validator: checkMobile, trigger: "blur" },
        ],
        projConstUnit: [
          { required: true, message: "请输入施工单位：", trigger: "blur" },
        ],
        constUnitLinker: [
          {
            required: true,
            message: "请输入联系人员(施工)：",
            trigger: "blur",
          },
        ],
        constUnitTel: [
          {
            required: true,
            message: "请输入联系电话(施工)：",
            trigger: "blur",
          },
          { validator: checkMobile, trigger: "blur" },
        ],
      },
      options: [],
      areaParentList: [], // 省
      areaList: [], //市
      copanyListInfo: [],
      projectState: projectState,
      geocoder:null
      // uploadUrl: config.uploadUrl,
    };
  },
  mounted() {
    this.getAreaInfo(0);
    this.getCopanyInfo();
  },
  methods: {
    updateMapCenter() {
      this.geocoder = new BMap.Geocoder();
    const address = this.editForm.projAddress;
    if (!address || address.trim() === '') return;

    this.geocoder.getPoint(address, (point) => {
      if (point) {
        this.center = { lng: point.lng, lat: point.lat };
        this.editForm.projLong = point.lng;
        this.editForm.projLot = point.lat;
      } else {
        this.$message.error('未能找到该地址，请检查输入');
      }
    }, '北京市');
  },
    getCopanyInfo() {
      organizationTree
        .getOrganizationCompanyTree({ orgaPid: 0 })
        .then((res) => {
          // 递归遍历 生成 {value, label, children}
          this.copanyListInfo = organizationCascader(res.data,);
        });
    },
    getAreaInfo(id) {
      let param = { pid: id };
      basic.getAreaList(param).then((res) => {
        console.log(res);
        if (id == 0) {
          this.areaParentList = res.data;
        } else {
          this.areaList = res.data;
        }
      });
    },
    cascaderChange(val) {
    
      let valLen = val.length;
      this.editForm.orgaPid = val[valLen - 1];
    },
    changeAreaParent(id) {
      // console.log(id)
      this.editForm.areaId = "";
      this.getAreaInfo(id);
      // 地图的
      this.areaParentList.forEach((item) => {
        if (item.id === id) {
          this.center = item.name;
        }
      });
    },
    changeArea(id) {
      // 市切换
      // 地图的
      this.areaList.forEach((item) => {
        if (item.id === id && item.name != "市辖区") {
          this.center = item.name;
        }
      });
    },
    submit() {
      console.log("this.editForm", this.editForm);
      this.$refs.editCopanyForm.validate((valid) => {
        if (valid) {
          if (this.editForm.projId) {
            // 编辑
            basic.updateProject(this.editForm).then((res) => {
              this.$message({
                message: "编辑成功",
                type: "success",
              });
              this.$emit("done");
            });
          } else {
            // 新增
            basic.AddProject(this.editForm).then((res) => {
              this.$message({
                message: "新增成功",
                type: "success",
              });
              this.$emit("done");
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    colseDialog() {
      this.$emit("close");
    },
    handler({ BMap, map }) {
      let mapStyle = {
        style: "midnight",
      };
      map.setMapStyle(mapStyle);
    },
    getPoint(e) {
      //点击地图获取一些信息，
      // this.show = true;
      this.editForm.projLong = e.point.lng; //通过  e.point.lng获取经度
      this.editForm.projLot = e.point.lat; //通过  e.point.lat获取纬度
      let geocoder = new BMap.Geocoder(); //创建地址解析器的实例
      geocoder.getLocation(e.point, (res) => {
        this.editForm.projAddress = res.address;
      });
    },
    resetFields() {
      if (this.$refs.editCopanyForm) {
        this.$refs.editCopanyForm.clearValidate();
        this.$refs.editCopanyForm.resetFields();
      }
    },
    uploadSuccess(val) {
      if (val.code == 200) {
        this.editForm.compIcon = val.message;
        this.$forceUpdate();
      }
    },
  },
};
</script>

<style scoped lang="less">
.edit-main {
  padding: 20px;
  border: 1px solid #eeeeee;
  height: 100%;
  border-radius: 4px;
}
.mapbox {
  height: 500px;
  width: 50%;
}
.box {
  width: 50%;
  height: 100%;
}
.editTitle {
  border: 1px solid #eeeeee;
  border-bottom: 0px solid #eeeeee;
  padding: 10px 0 10px 30px;
  border-radius: 6px 6px 0px 0px;
  font-weight: bold;
}
.inputFlex {
  display: flex;
  width: 100%;
  height: 100%;
}
</style>
